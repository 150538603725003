.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 55px;

  --foreground-color: var(--color-hallon1);
  --background-color: var(--color-hallon4);
  --border-color: var(--color-transparent);
  --border-width: 2px;
  --border-radius: var(--border-radius-lg);

  background: var(--background-color);
  color: var(--foreground-color);
  font-weight: var(--font-weight-medium);
  border: none;
  outline: none;
  border-radius: var(--border-radius);
  padding: 2px var(--spacing-3);
  line-height: 1;
  transition: color 0.2s, background-color 0.2s;

  margin: 4px; /* margin for focus-visible border */
}

.button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: none;
  border-style: solid;
  border-color: var(--border-color);
  border-width: var(--border-width);
  border-radius: var(--border-radius);

  pointer-events: none;
  transition: color 0.2s, background-color 0.2s;
}

.button:focus-visible::before {
  z-index: 1000;
  outline: 2px solid var(--color-ocean);
  outline-offset: 2px;
}

/* Primary Button */
.primary {
  --foreground-color: var(--color-hallon1);
  --background-color: var(--color-hallon4);
}

.primary::before {
  --border-color: var(--color-hallon4);
}

.primary:hover {
  --background-color: var(--color-hallon4-hover);
}

.primary:hover::before,
.primary:active::before {
  --border-color: var(--color-hallon1);
}

.primary:active {
  --background-color: var(--color-hallon4-active);
}

.primary:focus-visible {
  --background-color: var(--color-hallon4);
}

/* Disabled Primary Button */
.primary.disabled,
.primary.disabled:hover,
.primary.disabled:active,
.primary.disabled:focus-visible {
  --background-color: var(--color-gray1);
  --foreground-color: var(--color-dark-gray1);
}

.primary.disabled::before {
  --border-color: var(--color-gray1);
  outline: none;
}

/* Secondary Button */
.secondary {
  --foreground-color: var(--color-hallon1);
  --background-color: var(--color-white);
  --border-width: 1px;
}

.secondary::before {
  --border-color: var(--color-hallon1);
}

.secondary:hover::before,
.secondary:active::before,
.secondary:focus-visible::before {
  --border-width: 2px;
}

.secondary:hover {
  --background-color: var(--color-mine-shaft-hover);
}

.secondary:active {
  --background-color: var(--color-mine-shaft-active);
}

/* Disabled Secondary Button */
.secondary.disabled,
.secondary.disabled:hover,
.secondary.disabled:active,
.secondary.disabled:focus-visible {
  --background-color: var(--color-white);
  --foreground-color: var(--color-dark-gray2);
}

.secondary.disabled::before {
  --border-color: var(--color-dark-gray2);
  outline: none;
}

/* Transparent Secondary Button */
.secondary.transparent {
  --foreground-color: var(--color-white);
  --background-color: var(--color-transparent);
  --border-width: 1px;
}

.secondary.transparent::before {
  --border-color: var(--color-white);
}

.secondary.transparent:hover::before,
.secondary.transparent:active::before,
.secondary.transparent:focus-visible::before {
  --border-width: 2px;
}

.secondary.transparent:hover {
  --background-color: var(--color-white-hover);
}

.secondary.transparent:active {
  --background-color: var(--color-white-active);
}

/* Disabled Transparent Button */
.transparent.disabled,
.transparent.disabled:hover,
.transparent.disabled:active,
.transparent.disabled:focus-visible {
  --background-color: var(--color-white);
  --foreground-color: var(--color-dark-gray2);
}

.transparent.disabled::before {
  --border-color: var(--color-dark-gray2);
  outline: none;
}

/* Miscellaneous Button Styles */
.auto {
  width: auto;
  text-wrap: nowrap;
}

.small {
  width: fit-content;
}

.fullWidth {
  width: 100%;
}

.centered {
  display: flex;
  justify-content: center;
  margin: 4px auto; /* 4px margin for focus-visible border */
}

.loading {
  pointer-events: none;
}

.loading span {
  transition: opacity 0.1s ease-in;
  opacity: 0;
}

.icon-wrapper {
  margin-right: var(--spacing-15);
}

/* Disabled State */
.disabled {
  cursor: default;
}
